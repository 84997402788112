.HeaderContactInfo button {
    display: none;
}
.HeaderContactInfo:hover span {
    display: none;
}
.HeaderContactInfo:hover button {
    display: block;
}

/* for Keyword search Div */
.KeySearchDivContent img {
    width: 50px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    float: left;
    height: 30px;
    object-fit: contain;
}
/* for Keyword search Div */
.KeySearchDivContent {
    padding: 5px 10px;
    background: #fff;
    /* border: 1px solid #f5f5f5; */
}
.KeySearchDivContent b {
    font-weight: 500;
    color: #0f0f0f;
    display: -webkit-box!important;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 1;
    height: auto;
}
.KeySearchDivContent:hover {
    background: #f5f5f5;
}

.SearchAnything {
    position: relative;
}
.SearchAnythingResult {
    display: none;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 40px;
    z-index: 999999;
    /* max-height: 250px; */
    overflow: auto;
    overflow-x: hidden;
    background: #fff;
    border: 1px solid #c6aad2;
}
@media (max-width: 767px) {
    .SearchAnythingResult {
        border: 0px solid #c6aad2;
        top: 50px;
    }
}
.SearchAnything:hover .SearchAnythingResult {
    display: block;
}

.noksres:empty {
    display: none !important;
}
.SearchAnythingResult:empty {
    display: none !important;
}
.SearchAnythingMobile {
    position: relative;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 999999;
    right: 0px;
    bottom: 0px;
    background: #fff;
    padding: 10px;
}
.categoryNanaCont {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 999999;
}
.showOnHover:hover {
    display: block;
}
.showOnHover {
    display: none;
}
.categoryNanaCont .container-fluid .cmc_cont {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(245, 245, 245);
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    /* height: max-content; */
}
.categoryNanaCont .appie-single-service {
    position: absolute;
    bottom: 10px;
    left: 0px;
    right: 0px;
    padding: 10px;
}
.categoryNanaCont .appie-single-service a {
    padding: 5px;
    font-size: 15px;
} 
.categoryNanaCont .MainDivCont {
    position: relative;
}
.cmc_linkcat {
    font-size: 16px;
    font-weight: 400;
    display: block;
    border-bottom: 1px solid #f5f5f5;
    padding: 7px 10px;
    color: #000;
}
.cmc_linkcat:hover , .cmc_linkcat_active {
    /* background: #f1e9f7; */
    color: #7f13ab;
}
.cmc_linkcat .pull-right {
    float: right;
}

.ProfileMenuCont {
    display: inline;
    position: relative;
}
.ProfileMenuCont .ProfileMenu {
    display: none;
    position: absolute;
    right: 0px;
    top: 20px;
    background: #fff;
    z-index: 999999;
    border: 1px solid #f5f5f5;
    min-width: 180px;
    padding: 10px;
    word-break: break-all;
}
.ProfileMenuCont a {
    font-size: 14px !important;
    display: block;
    margin-bottom: 10px;
    font-size: 14px !important;
    display: block;
    margin-bottom: 10px;
    font-weight: 700;
    color: #7a3694;
}
.ProfileMenuCont img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    object-fit: cover;
    border-radius: 50%;
    margin-top: -5px;
}

.ProfileMenuCont:hover .ProfileMenu {
    display: block;
}
.btn-docsta-institute {
    color: #fff !important;
    background: #7a3694;
    padding: 5px 10px;
    border-radius: 50px;
    border-bottom: 1px solid #000 !important;
}
.btn-docsta-institute {
    color: #f5f5f5 !important;
}
.stickRYMenu .navSection-sjain {
    display: none;
}
.showLangOptCont {
    position: relative;
}
.showLangOptCont:hover .showLangOpt {
    display: block;
}
.showLangOpt {
    display: none;
    position: absolute;
    top: 30px;
    z-index: 2147483647;
    background: rgb(255, 255, 255);
    padding: 5px;
    min-width: 100px;
    border: 1px solid rgb(204, 204, 204);
    text-align: center;
    right: -45px;
}