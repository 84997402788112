.appie-loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
}

.appie-loader {
  opacity: 0;
}

.appie-loader.active {
  opacity: 100;
}

.appie-visible {
  transition: all 0.5s ease-in-out;
}

.appie-visible {
  opacity: 0;
}

.appie-visible.active {
  opacity: 100;
}

/* .appie-home-loader {
  transition: all 0.5s ease-in-out;
} */
.offcanvas_main_menu li ul.sub-menu {
  padding-left: 20 px;
  overflow: hidden;
  transition: all linear 0.65s;
}

.appie-fun-fact-box .appie-fun-fact-content .appie-fun-fact-item .title span {
  font-size: 30px !important;
  color: #fff !important;
}

.home-four-project {
  background: #eef1f6;
}

.home-four-footer {
  background: #ffffff;
}

.slick-dots li button:before {
  content: "";
}

.appie-showcase-item {
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1200px;
  }
}

.appie-services-2-area.appie-services-8-area {
  overflow: hidden;
}

@media only screen and (min-width: 300px) and (max-width: 1024px) {
  .appie-services-8-area .service-thumb {
    margin-left: 0;
    margin-right: 0;
  }
}

.save-comment input:checked+label::after {
  font-family: "Font Awesome 5 Pro";
  content: "\f00c";
}

.appie-testimonial-area {
  overflow: hidden;
}

.testimonial-about-slider-active .testimonial-parent-item {
  display: flex !important;
  justify-content: center;
}

.testimonial-box {
  width: 770px !important;
}

.testimonial-box-about-slider-small-active .slick-center.slick-current .item .thumb img {
  width: 70px;
  display: inline-block;
}

.testimonial-box-about-slider-small-active .item .thumb img {
  display: inline-block;
  width: 40px;
}

.testimonial-box-about-slider-small-active .item .thumb {
  align-items: center;
}

.testimonial-box-about-slider-small-active .slick-center.slick-current .item {
  margin-top: -20px;
}

@media only screen and (min-width: 300px) and (max-width: 1024px) {
  .testimonial-about-slider-active .testimonial-box {
    box-shadow: none;
    background: none;
  }
}

@media only screen and (min-width: 770px) and (max-width: 1024px) {
  .testimonial-about-slider-active .slick-arrow.next {
    right: 15px;
  }

  .testimonial-about-slider-active .slick-arrow.prev {
    left: 15px;
  }
}

.navSection-sjain {
  /* background: #2b8a4f; */
  /* 7f13ab   */
  /* background: linear-gradient(0deg,#4b4b4b,#2a2a2a); */
  background: #f5f5f5;
  padding: 0px !important;
}
.navSection-sjain ul li {
  padding: 10px;
}
.navSection-sjain .appie-header-main-menu ul>li>a {
  color: #000;
  transition: 0.5s;
}

.navSection-sjain .appie-header-main-menu ul>li>a:hover {
  color: #46105a;
  /* margin-top: -5px; */
  border-bottom: 1px solid #000;
}

.InformationPage ul {
  list-style-type: disc;
  padding-left: 50px;
}

@media only screen and (min-width: 771px) {
  .hideondesktop {
    display: none !important;
  }
}

@media only screen and (max-width: 770px) {
  .hideonmobile {
    display: none !important;
  }
  .blockonmobile {
    display: block;
  }
}
.Sample_Certificate .styles_row__2gmnz {
  column-count: 1 !important;
}
.hide_empty_div:empty {
  display: none !important;
}
.a_class {
  cursor: pointer;
}
.docsta_color {
  color: #7f13ab !important;
}
.audidTips {
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 15px 20px 0px;
    transition: all 0.3s ease-out 0s;
}
.cartCounter {
  position: absolute;
  color: white;
  top: -5px;
  right: -23px;
  display: block;
  background: #7f13ab;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 19px;
}
@media only screen and (max-width: 771px) {
  .cartCounter {
    width: 14px;
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    top: -1px;
    right: -5px;
  }
}
.HomeCourseCategorySlider .slick-slide {
  width: max-content !important;
}
.HomeCourseCategorySlider .slick-slide span {
  border-radius: 0;
  font-size: 15px;
  font-weight: 700;
  color: #000;
  background: #f0f0f0;
  padding: 10px 28px;
  margin-right: 15px;
  cursor: pointer;
}
.HomeCourseCategorySlider .slick-slide:hover span, .HomeCourseCategorySlider .slick-slide span.active {
  background: #7f13ab;
  color: #fff;
}
.HomeCourseCategorySlider .slick-slide span.active {
  text-decoration: underline;
}

/* For the Audio Player */
.audioPlayTrackCont b {
  padding-top: 10px;
  display: block;
  padding-left: 10px;
  color: #7a3694;
}
.audioPlayTrackCont {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0px;
  background: #f5f5f5;
  z-index: 999999;
  padding: 0px;
  border-top: 0.5px solid #000;
}
.audioPlayTrack {
  position: relative;
}
.audioPlayTrack button.ry {
  position: absolute;
  left: 20px;
  top: 15px;
  background: #f5f5f5;
  border: 0px;
}


/* Customize the appearance of the audio player */
.custom-audio {
  width: 100%; /* Adjust as needed */
}

/* Style the default audio controls */
.custom-audio::-webkit-media-controls-panel {
  background-color: #f8f8f8; /* Background color */
  color: #333; /* Text color */
  border-radius: 0px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-audio::-webkit-media-controls-play-button,
.custom-audio::-webkit-media-controls-pause-button {
  font-size: 20px; /* Adjust the icon size */
}
@media only screen and (max-width: 771px) {
  .custom-audio::-webkit-media-controls-timeline {
    width: 90px; /* Set the slider width to 100% */
    margin-right: -15px;
    padding-right: 0px;
  }
  /* .custom-audio::-webkit-media-controls-current-time-display, */
  .custom-audio::-webkit-media-controls-time-remaining-display,
  .custom-audio::-webkit-media-controls-mute-button,
  .custom-audio::-webkit-media-controls-volume-slider,
  .custom-audio::-webkit-media-controls-seek-back-button,
  .custom-audio::-webkit-media-controls-seek-forward-button,
  .custom-audio::-webkit-media-controls-toggle-closed-captions-button,
  .custom-audio::-webkit-media-controls-fullscreen-button {
    display: none; /* Hide certain controls */
  }
}

/* Style the progress bar */

/* For the Audio Player */
.topMessageClose {
  border: 1px solid #fff;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  line-height: 20px;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: 10px;
  right: 10px;
}
@media only screen and (max-width: 771px) {
  .topMessageClose {
    height: 17px;
    width: 17px;
    line-height: 15px;
    font-size: 11px;
    top: 15px;
    right: 7px;
  }
}
.fa-star, .fa-star-half-alt {
  color: #ffbe00;
}
.viewAllMobLink {
  font-size: 14px;
  border: 1px solid rgb(0, 0, 0);
  padding: 1px 5px;
  border-radius: 50%;
}
.FiltSB label {
  margin-bottom: 0px;
}
.phoneNumberInpCont {
 position: relative;
 height: 40px;
 border: 1px solid #dedede; 
 padding-left: 50px;
}
.phoneNumberInpCont input.PhoneInputInput {
  border: 0px solid #dedede;
  width: 100%;
  padding-left: 60px;
}
.phoneNumberInpCont .PhoneInputCountry {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 999;
  background: #f5f5f5;
  min-width: max-content;
  padding: 0px 10px;
}
.phoneNumberInpCont .PhoneInput {
  display: block;
}
a {
  color: #7a3694;
}
a:hover {
  /* font-weight: bold; */
  color: #7a3694;
}
.TitleOneLine {
  display: -webkit-box!important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  height: 1.5em;
}
@media only screen and (max-width: 771px) {
  body {
    padding-bottom: 60px;
  }
}
.slick-track {
  margin-left: initial;
  margin-right: initial;
}
.btn-purple {
  background-color: #7a3694 !important;
  color: #f5f5f5;
}
.btn-purple:hover {
  color: #fff;
}

.ShowLoaderPage {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 999;
  text-align: center;
  padding-top: 20%;
  background: #fffffff7;
}
.ShowLoaderPage img {
  max-width: 60px;
}
.text-purple {
  color: #7a3694 ;
}
.LoadingAnimaCourse img {
  max-width: 95px;
}
.LoadingAnimaCourse {
  animation: upDown 2s linear infinite;
  text-align: center;
}
@keyframes upDown {
  0% {
    padding-top: 0;
  }
  50% {
    padding-top: 50px;
  }
  100% {
    padding-top: -50px;
  }
}
.MCQsIcon {
  max-width: 20px;
  width: 20px;
  height: 20px;
}