.whatsapp_us {
    position: fixed;
    left: 0px;
    bottom: 0px;
    z-index: 999999;
    margin-left: 30px;
    max-width: 70px;
    margin-bottom: 30px;
    animation: bounce2 3s ease infinite;
}

@keyframes bounce2 {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
    40% {transform: translateY(-30px);}
    60% {transform: translateY(-15px);}
    80% {transform: translateY(15px);}
}

.call_us {
    position: fixed;
    right: 0px;
    bottom: 0px;
    z-index: 999999;
    margin-right: 30px;
    max-width: 70px;
    margin-bottom: 30px;
    animation: shake 4s ease infinite;
}

@keyframes shake {
	0%, 100% {transform: translateX(0);}
	10%, 30%, 50%, 70%, 90% {transform: translateX(-5px);}
	20%, 40%, 60%, 80% {transform: translateX(5px);}
}

.back-to-top  {
    display: none !important;
}

@media only screen and (max-width: 600px) {
    .whatsapp_us {
        margin-left: 10px;
        max-width: 44px;
        margin-bottom: 10px;
    }
    .call_us {
        margin-left: 10px;
        max-width: 44px;
        margin-bottom: 10px;
    }
} 